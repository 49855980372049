<!-- eslint-disable vue/html-self-closing -->
<!-- eslint-disable vue/max-attributes-per-line -->
<!-- eslint-disable vue/valid-v-on -->
<template>
  <b-overlay :show="isLoading" rounded="sm">
    <div>
      <b-modal
        id="modal-delete"
        cancel-title="خیر"
        centered
        ok-title="بله"
        title="حذف "
        @ok="deleteASkill"
      >
        <span v-if="tempSkill">
          آیا از حذف {{ tempSkill.title }} اطمینان دارید ؟
        </span>
      </b-modal>
      <b-modal
        id="modal-edit"
        cancel-title="بستن"
        centered
        ok-title="ثبت"
        title="بروزرسانی مهارت"
        @ok="updateASkill"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group label="نام مهارت" label-cols="12" v-if="tempSkill">
              <b-form-input
                placeholder="نجاری یا ..."
                type="text"
                v-model="tempSkill.title"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal
        id="modal-Add"
        cancel-title="بستن"
        centered
        ok-title="ثبت"
        title="افزودن "
        @ok="createANewSkill"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group label="نام مهارت" label-cols="12">
              <b-form-input
                placeholder="نجاری یا ..."
                type="text"
                v-model="newSkillObject.title"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>
      <!-- Table Container Card -->
      <b-card class="mb-0" no-body>
        <b-col class="my-2" lg="12" md="12">
          <b-row class="">
            <!-- <div
              class="w-100 d-flex align-items-center justify-content-between"
            > -->
            <b-col cols="12" md="12" lg="8">
              <div class="d-flex align-items-center w-100">
                <b-form-input
                  v-model="searchCommand"
                  class="search-product"
                  placeholder="جستجوی مهارت ها"
                />
                <b-button variant="primary" class="ml-2" @click="getAllSkills">
                  جستجو
                </b-button>
              </div>
            </b-col>
            <b-col cols="12" lg="2" md="12">
              <b-button
                v-b-modal.modal-Add
                variant="primary"
                class="my-2 my-lg-0"
              >
                افزودن مهارت
              </b-button>
            </b-col>
            <b-col cols="12" lg="2" md="12">
              <clear-sorts-btn @clear="sortBy = ''"></clear-sorts-btn>
            </b-col>
            <!-- </div> -->
          </b-row>
        </b-col>

        <b-table
          ref="refUserListTable"
          :fields="myTableColumns"
          :items="skillsList"
          :sort-by.sync="sortBy"
          bordered
          class="position-relative"
          empty-text="موردی موجود نیست!"
          primary-key="id"
          responsive
          show-empty
          striped
        >
          <template #cell(actions)="data">
            <div class="d-flex align-items-center justify-content-around">
              <div
                class="cursor-pointer d-flex flex-row"
                @click="renderEditModal(data.item)"
              >
                <feather-icon icon="EditIcon" class="text-primary" size="20" />
              </div>
              <div
                class="cursor-pointer d-flex flex-row"
                @click="renderDeleteModal(data.item)"
              >
                <feather-icon class="text-danger" icon="TrashIcon" size="20" />
              </div>
            </div>
          </template>
        </b-table>
        <!-- Pagination -->
        <b-col
          v-if="skillsList && skillsList.length > 0"
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end pb-2"
        >
          <b-pagination
            v-model="page"
            :total-rows="totalCount"
            :per-page="size"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                style="transform: rotate(180deg)"
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
/* eslint-disable  */

import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BFormFile,
  BBadge,
  BDropdown,
  BDropdownItem,
  BFormTextarea,
  BPagination,
  BOverlay,
  BModal,
  BFormGroup,
  BFormSelect,
  BFormCheckbox,
} from "bootstrap-vue";
import {
  CreateSkill,
  GetAllSkills,
  UpdateASingleSkill,
  DeleteASingleSkill,
} from "@/libs/Api/Skills";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import ClearSortsBtn from "@/views/components/clearSortsBtn.vue";

export default {
  title: "مهارت ها  - پنل ادمین انزا مد",
  name: "Skills",
  components: {
    ClearSortsBtn,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormFile,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    BFormSelect,
    BFormTextarea,
  },
  watch: {
    page: {
      handler(val) {
        this.getAllSkills();
      },
    },
  },
  data() {
    return {
      isLoading: false,
      sortBy: "",
      skillsList: null,
      newSkillObject: {
        id: 0,
        title: null,
      },
      page: 1,
      size: 10,
      totalCount: null,
      searchCommand: null,
      tempSkill: null,
      myTableColumns: [
        {
          key: "id",
          label: "شناسه",
          sortable: true,
        },
        {
          key: "title",
          label: "نام",
          sortable: true,
        },
        {
          key: "actions",
          label: "عملیات",
        },
      ],
    };
  },
  async created() {
    await this.getAllSkills();
  },

  methods: {
    renderEditModal(skillObject) {
      this.tempSkill = JSON.parse(JSON.stringify(skillObject));
      this.$bvModal.show("modal-edit");
    },
    async updateASkill() {
      try {
        this.isLoading = true;
        let _this = this;
        let updateASingleSkill = new UpdateASingleSkill(_this);
        updateASingleSkill.setRequestParamDataObj(_this.tempSkill);
        await updateASingleSkill.fetch((response) => {
          if (response.isSuccess) {
            _this.getAllSkills();
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: `عملیات موفق`,
                icon: "EditIcon",
                variant: "success",
                text: `مهارت با موفقیت ویرایش شد.`,
              },
            });
            _this.tempSkill = null;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async deleteASkill() {
      try {
        this.isLoading = true;
        let _this = this;
        let deleteASingleSkill = new DeleteASingleSkill(_this);
        deleteASingleSkill.setId(_this.tempSkill.id);
        await deleteASingleSkill.fetch((response) => {
          if (response.isSuccess) {
            _this.tempSkill = null;
            _this.getAllSkills();
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: `عملیات موفق`,
                icon: "CheckIcon",
                variant: "success",
                text: `مهارت حذف شد.`,
              },
            });
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    renderDeleteModal(skillObject) {
      this.tempSkill = skillObject;
      this.$bvModal.show("modal-delete");
    },
    async getAllSkills() {
      try {
        this.isLoading = true;
        let _this = this;
        let getAllSkills = new GetAllSkills(_this);
        let queryParams = {
          page: _this.page,
          size: _this.size,
        };
        if (_this.searchCommand) {
          queryParams["title"] = _this.searchCommand;
        }
        getAllSkills.setRequestParam(queryParams);
        await getAllSkills.fetch((response) => {
          if (response.isSuccess) {
            _this.totalCount = response.data.totalCount;
            _this.skillsList = response.data.data;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },

    async createANewSkill() {
      try {
        this.isLoading = true;
        let _this = this;
        let createSkill = new CreateSkill(_this);
        createSkill.setRequestParamDataObj(_this.newSkillObject);
        await createSkill.fetch((response) => {
          if (response.isSuccess) {
            _this.newSkillObject = {
              id: 0,
              title: null,
            };
            _this.getAllSkills();
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: `عملیات موفق`,
                icon: "TrashIcon",
                variant: "success",
                text: `مهارت جدید اضافه شد.`,
              },
            });
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
