import WebServiceRequest from "../Api/WebServiceRequest";

class CreateSkill extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Skill/Create");
  }

  setRequestParamDataObj(value) {
    super.setRequestParamDataObj(value);
  }
}

class GetAllSkills extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Skill/Get");
  }

  setRequestParam(value) {
    super.setRequestParam(value);
  }
}

class GetASingleSkill extends WebServiceRequest {
  constructor(context) {
    super(context);
  }

  setId(id) {
    super.setTag(`Skill/Get/${id}`);
  }
}

class UpdateASingleSkill extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Skill/Update");
  }

  setRequestParamDataObj(value) {
    super.setRequestParamDataObj(value);
  }
}

class DeleteASingleSkill extends WebServiceRequest {
  constructor(context) {
    super(context);
  }

  setId(id) {
    super.setTag(`Skill/Delte/${id}`);
  }
}

export {
  CreateSkill,
  GetAllSkills,
  GetASingleSkill,
  UpdateASingleSkill,
  DeleteASingleSkill,
};
